<template>
    <div class="metadataEntityInfo">
        <div class="handle-btn">
            <el-button type="primary" size="small" @click="handleAdd">
                新增
            </el-button>
            <el-button type="success" size="small" @click="multiSave">
                批量保存
            </el-button>
            <el-button type="danger" size="small" @click="multiDel">
                批量删除
            </el-button>
        </div>
        <div class="table-con">
            <el-table
                :data="tableData"
                stripe
                style="width: 100%"
                height="calc(100% - 35px)"
                @selection-change="handleSelectionChange"
            >
                >
                <el-table-column
                    type="selection"
                    width="55"
                >
                </el-table-column>
                <el-table-column
                    label="信息项"
                >
                    <template slot-scope="scope">
                        <el-input
                            v-model="scope.row.info_name"
                            @focus="choseInfo(scope.row,scope.$index)"
                            placeholder="请输入内容"
                        ></el-input>
                    </template>
                </el-table-column>
                <el-table-column
                    label="关联信息项"
                >
                    <template slot-scope="scope">
                        <el-input
                            v-model="scope.row.relation_info_name"
                            @focus="choseRelationInfo(scope.row,scope.$index)"
                            placeholder="请输入内容"
                        ></el-input>
                    </template>
                </el-table-column>
                <el-table-column
                    label="备注"
                >
                    <template slot-scope="scope">
                        <el-input v-model="scope.row.remarks" placeholder="请输入内容"></el-input>
                    </template>
                </el-table-column>
                <el-table-column
                    label="显示顺序"
                >
                    <template slot-scope="scope">
                        <el-input v-model="scope.row.sort_num" placeholder="请输入内容"></el-input>
                    </template>
                </el-table-column>
            </el-table>
            <div class="table-page">
                <el-pagination
                    @size-change="handleSizeChange"
                    @current-change="handleCurrentChange"
                    :current-page="pagetion.pageindex"
                    :page-sizes="pagetion.pagesizes"
                    :page-size="pagetion.pagesize"
                    layout="total, sizes, prev, pager, next, jumper"
                    :total="pagetion.total"
                >
                </el-pagination>
            </div>
        </div>
    </div>
</template>

<script>
import util from '@/utils/util';
export default {
    components: {},
    props: {
        entityId: {
            type: String,
        },
    },
    data() {
        return {
            tableData: [],
            pagetion: {
                pageindex: 1,
                pagesize: 10,
                total: 0,
                pagesizes: [10, 20, 30, 40, 50, 100],
            },
            selectData: [], // 多选选中数据集合
            currentData: {}, // 当前行数据
            currentIndex: 0, // 当前数据index
        };
    },
    watch: {
        entityId(val) {
            if (val) {
                this.entityId = val;
                this.getEntityInfo();
            }
        },
    },
    computed: {},
    methods: {
        // 获取信息项
        getEntityInfo() {
            this.$axios
                .get('/interfaceApi/datacenter/metadata/entity/info/relation/getpage/' + this.entityId
                + '?pageindex=' + this.pagetion.pageindex + '&pagesize=' + this.pagetion.pagesize)
                .then(res => {
                    if (res) {
                        this.pagetion.total = res.total;
                        this.tableData = res.rows;
                    }
                }).catch(error => {
                    this.$message.error(error.ErrorCode.Message);
                });
        },
        // 新增一行
        handleAdd() {
            const id = util.getGuid();
            const objData = {
                id: id,
                entity_id: '',
                info_id: '',
                info_name: '',
                relation_entity_id: '',
                relation_info_id: '',
                relation_info_name: '',
                remarks: '',
            };
            this.tableData.unshift(objData);
        },
        // 切换页码
        handleCurrentChange(page) {
            this.pagetion.pageindex = page;
            this.getEntityInfo();
        },
        // 切换每页条数
        handleSizeChange(size) {
            this.pagetion.pagesize = size;
            this.getEntityInfo();
        },
        // 多选事件
        handleSelectionChange(val) {
            this.selectData = val;
        },
        // 批量保存
        multiSave() {
            if (!this.entityId) {
                this.$message.warning('请先选择树节点');
                return;
            }
            if (this.selectData.length < 1) {
                this.$message.warning('请先勾选将要保存的数据');
                return;
            }
            this.$axios
                .post('/interfaceApi/datacenter/metadata/entity/info/relation/save/' + this.entityId, this.selectData)
                .then(res => {
                    if (res) {
                        this.$message.success('保存成功');
                        this.getEntityInfo();
                    }
                }).catch(error => {
                    this.$message.error(error.ErrorCode.Message);
                });
        },
        // 批量删除
        multiDel() {
            if (this.selectData.length < 1) {
                this.$message.warning('请先勾选将要保存的数据');
                return;
            }
            let ids = '';
            this.selectData.map(item => {
                ids += item.id + ',';
            });
            ids = ids.substring(0, ids.length - 1);
            this.$axios
                .delete('/interfaceApi/datacenter/metadata/entity/info/relation/' + ids)
                .then(res => {
                    if (res) {
                        this.$message.success('删除成功');
                        this.getEntityInfo();
                    }
                }).catch(error => {
                    this.$message.error(error.ErrorCode.Message);
                });
        },
        // 选择信息项
        choseInfo(row, index) {
            this.currentData = row;
            this.currentIndex = index;
            this.$toast({
                title: true,
                text: '选择信息项',
                type: 'eject',
                t_url: 'dataBaseCenter/catalogManage/infoTable',
                viewPosition: 'view',
                extr: { closeBtn: {}, entityId: this.entityId, closeCall: this.selectedInfo },
            });
        },
        // 信息项选择完成
        selectedInfo(data) {
            this.currentData.entity_id = data.entity_id;
            this.currentData.info_name = data.name;
            this.currentData.info_id = data.id;
            this.tableData.splice(this.currentIndex, 1, this.currentData);
        },
        // 选择关联信息项
        choseRelationInfo(row, index) {
            this.currentData = row;
            this.currentIndex = index;
            this.$toast({
                title: true,
                text: '选择关联信息项',
                type: 'eject',
                t_url: 'dataBaseCenter/catalogManage/relationInfoTable',
                viewPosition: 'view',
                extr: { closeBtn: {}, closeCall: this.selectedRelationInfo },
            });
        },
        // 关联信息项选择完成
        selectedRelationInfo(data) {
            this.currentData.relation_entity_id = data.entity_id;
            this.currentData.relation_info_id = data.id;
            this.currentData.relation_info_name = data.name;
            this.tableData.splice(this.currentIndex, 1, this.currentData);
        },
    },
    created() {
        if (!this.entityId) {
            return;
        }
        this.getEntityInfo();
    },
    mounted() {},
};
</script>
<style lang="stylus">
.metadataEntityInfo
    padding .1rem
    height 100%
    .handle-btn
        margin-bottom .1rem
        text-align right
    .table-con
        height calc(100% - 52px)
        .el-table
            border 1px solid #f0f0f0
            border-radius 2px
            thead
                color #022782
                background #EDF0F5
                th
                    padding .06rem 0
                    background #EDF0F5
                    text-align center
            tr
                td
                    padding .06rem 0
                    border-bottom 0
                    text-align center
                &.el-table__row--striped
                        background #f8fafd
        .table-page
            text-align right
            margin-top .1rem
</style>